// import i18n from '@/i18n/index'

export default {
  tableDataItem: [
    { prop: 'id', label: '', Object: 'value', width: '100' },
    { prop: 'user', label: '', Object: 'value', width: '120' },
    { prop: 'started_at', label: '', Object: 'value', width: '200' },
    { prop: 'stopped_at', label: '', Object: 'value', width: '200' },
    { prop: 'status', label: '', Object: 'value', width: '120' },
    { prop: 'payment_method', label: '', Object: 'value', width: '150' },
    { prop: 'payment_status', label: '', Object: 'value', width: '150' },
    { prop: 'total_kwh', label: '', Object: 'value', width: '120' },
    { prop: 'total_amount', label: '', Object: 'value', width: '120' },
    { prop: 'charge_point', label: '', Object: 'value', width: '300' }
  ]
}

// export const searchFormData = [
//   { name: '名称', query_name: 'name_i_cont', type: 'text' }
// ]

// export const tableData = [
//   {
//     id: 135929,
//     user: 'Anton Krastev',
//     roaming: '',
//     started: '2022-04-30 19:21:31',
//     duration: '3h 12m',
//     sessionStatus: 'Finished',
//     paymentMethod: '**** 7873',
//     paymentStatus: 'Pending',
//     totalkWh: '231',
//     totalAmount: '$18.00',
//     evseId: '2814347 (22kW AC Type 2)',
//     CP: 'KEBA KeContact P30 X (Type 2 AC, no cable)',
//     location: 'Svetelskystraße',
//     tariffSnapshot: 'Per kWh tariff for AC(AT)'
//   }
// ]
